@import "../mainColors";

.partner-details-wrapper {
  padding: 60px;
  background-color: $bgGreyColor;
  min-height: 100vh;
  color: black;
  font-size: 14px;



  .arrow-back {
    margin: 50px 0 20px 20px;

    img {
      cursor: pointer;
    }
  }

  .partner-details-content {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    position: relative;
    min-height: 400px;
    .loading {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .main-image {
      width: 20%;

      .main-image-wrapper {
        width: 100%;
        background: #FFF;
        box-shadow: 0 2px 4px #00000029;
        border-radius: 4px;
        padding-bottom: 75%;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .content-wrapper {
      width: 40%;

      .content {
        margin: 40px 20px;

        .name{
          color: black;
          font-family: Bold;
        }
        .title {
          margin-bottom: 40px;
        }

        .description{
          word-break: break-word !important;
          p{
            word-break: break-word;
            span{
              word-break: break-word;
            }
          }
        }

        .partner-link {
          width: 300px;
          height: 50px;
          background: #FFF;
          box-shadow: 0 3px 6px #00000029;
          margin-top: 40px;
          color: #45A1EF;
          text-transform: uppercase;
          font-size: 13px;
          font-weight: 600;
        }
      }
    }

    .brands-content {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .brand-item {
        cursor: pointer;
        width: calc(100% / 3);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        .brand-item-wrapper {
          width: 100%;
          background: #FFF;
          box-shadow: 0 2px 4px #00000029;
          border-radius: 4px;
          padding-top: 75%;
          position: relative;
          transition: all .3s;
          &:hover {
            transform: scale(1.06);
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            //padding: 10px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1000px) {
  .partner-details-wrapper {
    height: 100%;

    .partner-details-content {
      flex-wrap: wrap;

      .main-image {
        width: 30%;
      }

      .content-wrapper {
        width: 100%;

        .content {
          margin-left: 0;

          .title {
          }

          .partner-link {
            width: 300px;
            height: 50px;
          }
        }
      }

      .brands-content {
        width: 100%;

        .brand-item {
          width: 25%;
        }
      }
    }
  }
}

@media all and (max-width: 800px) {
  .partner-details-wrapper {
    padding: 30px;

    .partner-details-content {
      .main-image {
        min-width: 170px;
      }

      .brands-content {
        .brand-item {
          width: calc(100% / 3);
        }
      }
    }
  }
}

@media all and (max-width: 600px) {
  .partner-details-wrapper {
    font-size: 12px;
    padding: 15px;

    .partner-details-content {
      .content-wrapper {
        .content {
          .partner-link {
            width: 100%;
            height: 50px;
            font-size: 11px;
          }
        }
      }

      .brands-content {
        .brand-item {
          width: 50%;
        }
      }
    }
  }
}
