$dark: #272727;
$light-grey: #c9c6c6;

.custom-page {
  width: 100%;
  padding: 60px 0 0 60px;
  margin: 0;

  .page-content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .editor-data {
      width: 50%;
      position: relative;
      z-index: 2;
    }

    .image-wrapper {
      margin-bottom: 20px;
      width: 47%;
      height: 60vh;
      position: relative;


      .custom-page-blue-bg {
        position: absolute;
        bottom: -12%;
        left: -10%;
        width: 50%;
        height: 40vh;
        background-color: #d9ecfc;
        z-index: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 1;
      }
    }

  }

  .media-wrapper {
    display: flex;
    flex-wrap: wrap;

    .media-item {
      width: 20%;
      padding-right: 20px;
      padding-bottom: 20px;

      .image {
        padding-bottom: 56.25%;
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        padding-right: 20px;
      }

      img {
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .custom-page {
    .page-content {
      .image-wrapper {
        width: 47%;
        height: 50vh;
        .custom-page-blue-bg{
          height: 30vh;
        }
        img {
          //max-width: 100%;
        }
      }
    }

    .media-wrapper {
      .media-item {
        width: 25%;
      }
    }
  }
}

@media all and (max-width: 900px) {
  .custom-page {
    .page-content {
      flex-wrap: wrap;
      .editor-data{
        width: 100%;
        order: 2;
      }
      .image-wrapper {
        width: 100%;
        order: 1;
        img {
        }

        .custom-page-title {
          margin-left: 30px;
        }
      }
    }

    .media-wrapper {
      .media-item {
        width: calc(100% / 3);
      }
    }
  }
}

@media all and (max-width: 700px) {
  .custom-page {
    padding: 30px 0 0 30px;
    .page-content {
      .image-wrapper {
      }
    }

    .media-wrapper {
      .media-item {
        width: 50%;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .custom-page {
    .page-content {
      .image-wrapper {
        height: 200px;

        .custom-page-blue-bg{
          display: none;
        }
      }
    }

    .media-wrapper {
      .media-item {
        width: 100%;
      }
    }
  }
}
