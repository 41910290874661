@import "mainColors";
@import "mixins";

.ant-checkbox-wrapper {
  span{
    transition: opacity .2s;
  }
  & + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: $brandColor;
    }
  }

  .ant-checkbox-input:focus {
    border-color: $brandColor;
  }

  .ant-checkbox-disabled {
    opacity: 0.4;

    .ant-checkbox-inner::after {
      border-color: white !important;
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #ced4da;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $brandColor;
      border-color: $brandColor;
    }

    &:after {
      border-color: $brandColor;
    }
  }
}

.ant-switch-checked {
  background-color: $brandColor !important;
}

.ant-click-animating-node {
  border: 0 solid $brandColor
}


.ant-tabs-bar {
  border-bottom: 1px solid #d9d9d9;
}

.ant-tabs.ant-tabs-card {
  .ant-tabs-card-bar {
    .ant-tabs-tab {
      font-weight: 500;
    }

    .ant-tabs-tab-active {
      border-bottom: 1px solid #fff;
    }
  }
}

.ant-calendar-selected-day .ant-calendar-date {
  background: $brandColor !important;
  color: white;

}

.ant-calendar .ant-calendar-ok-btn {
  background-color: $brandColor !important;
}

.ant-calendar-today .ant-calendar-date {
  color: $brandColor !important;
}

.ant-calendar-selected-day {
  &.ant-calendar-today .ant-calendar-date {
    color: white !important;
  }
}

.ant-select-selection:hover {
  border-color: $brandColor !important;
}

.ant-input:hover {
  border-color: $brandColor !important;
  border-right-width: 1px !important;
}

.ant-select-dropdown-menu {
  @include custom-scrollbar-y;
}
.ant-select-arrow{
  margin-top: 0;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.65);
}
.ant-select-selection__placeholder{
  color: rgba(0, 0, 0, 0.65);
}
.ant-dropdown-menu {
  .ant-dropdown-menu-item {
    .language-icon {
      width: 22px;
      height: 16px;
      object-fit: cover;
      margin-right: 10px;
    }
  }
}