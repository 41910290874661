$blue-color: #7295fe;
$light-grey: #c9c6c6;

.layout-footer {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 15px 20px 15px 60px;
  font-size: 14px;
  a{
    color: black;
   &:hover{
     text-decoration: none;
   }
  }
}


@media all and (max-width: 550px) {
  .layout-footer {
    flex-direction: column;
    padding: 15px;
    text-align: center;
  }
}