$dark:  #272727;
.partners-slider {
  padding: 60px;
  overflow: hidden;
  position: relative;
  .hompage-partners-header {
    margin: 40px 8px;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 42px;
  }
  .slick-slider {
    height: 100%;
    .slick-list{
      height: 225px;
    }
    .slick-track {
      height: 100%;
      .slick-slide {
        padding: 8px;
        >div {
          height: 100%;
        }
      }
    }
  }
  .slider-arrows-block {
    display: flex;
    justify-content: space-between;
  }
  .slider-arrows {
    display: flex;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    .slider-btn {
      padding: 5px;
      width: 64px;
      height: 64px;
      cursor: pointer;
      transition: background-color .3s;
      &.prev-btn {
        background: #45A1EF url("../../images/homepage/arrow_left_white.svg") no-repeat center;
        margin-right: 16px;
        &:hover {
          background : #fff url("../../images/homepage/arrow_right_blue.svg") no-repeat center;
          box-shadow: 0px -2px 4px #00000029;
          transform: rotate(180deg);
        }
      }
      &.next-btn {
        background: #45A1EF url("../../images/homepage/arrow_left_white.svg") no-repeat center;
        transform: rotate(180deg);
        &:hover {
          box-shadow: 0px 2px 4px #00000029;
          background: #fff url("../../images/homepage/arrow_right_blue.svg")  no-repeat center;
          transform: rotate(360deg);
        }
      }
    }
  }

  .slick-list {
    border-radius: 6px 0 0 6px;
    height: 100%;
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
    .hompage-partners-header {
      font-size: 30px;
    }
    .slider-arrows-block {
      flex-direction: column;
      >.row {
        order: -1;
      }
    }
    .slider-arrows {
      margin-bottom: 20px;
      position: unset;
      justify-content: flex-end;
      .slider-btn {
        width: 50px;
        height: 50px;
      }
    }
  }
}
