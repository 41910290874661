.page-header{
  margin-bottom: 30px;padding-top: 80px;
  .search-page-logo{
    margin-bottom: 20px;
  }
  .page-title{
    color: black;
    font-family: Bold;
  }
}
@media all and (max-width: 500px){
  .page-header{
    padding-top: 60px;
    .search-page-logo{
      width: 80px;
    }
    .page-title{
      font-size: 20px;
    }
  }
}