$blue-color: #7295fe;
@import "../mainColors";
.contact-page {
  padding: 40px 0 0 60px;
  position: relative;

  .recaptcha-wrapper {

  }

  .contact-top-wrapper {
    position: relative;

    .container-contacts {
      position: absolute;
      width: 500px;
      top: 120px;
      z-index: 2;
      height: 540px;
      overflow: auto;
      padding: 5px;

      img {
        transition: all .3s;
      }

      .activeAddress {
        transform: rotate(45deg);
        top: 35%;
      }

      .ant-collapse {
        border: 0;
        background-color: transparent;

        .ant-collapse-item {
          border: 0;
          margin-bottom: 10px;

          .ant-collapse-content {
            border: 0;

            .ant-collapse-content-box {
              background: #f8f8f8;

              .info-wrapper {
                .info-content {
                  display: flex;
                  margin-bottom: 20px;

                  .title {
                    width: 130px;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }

      .ant-collapse-header {
        background-color: white;
        box-shadow: 0 3px 6px #00000029;
        border-radius: 4px;
        font-weight: bold;

        .ant-collapse-arrow {
          width: 12px;
          height: 12px;
        }
      }
    }

    .bg-containers {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .light-blue {
        width: 600px;
        height: 400px;
        background-color: $bgBlueColor;
        opacity: 0.54;
      }

      .dark-blue {
        width: 90%;
        height: 80vh;
        background-color: $bgBlueColor;
      }
    }

    .map-wrapper {
      width: 75%;
      height: 650px;
      background-color: grey;
      position: absolute;
      z-index: 1;
      top: 50px;
      right: 60px;
    }
  }

  .contact-wrapper-bottom {
    position: relative;
    height: 300px;

    img {
      position: absolute;
      top: -300px;
      height: 600px;
      width: 75%;
    }

    .send-message {
      background-color: white;
      width: 50%;
      //height: 400px;
      position: absolute;
      top: -250px;
      right: 60px;
      padding: 60px;
      box-shadow: 0 3px 6px #00000029;
      border-radius: 4px;

      .send-message-title {
        margin-bottom: 40px;
      }

      .form-wrapper {
        display: flex;
        justify-content: space-between;

        .left, .right {
          width: 46%;
        }

        .input-content {
          width: 100%;
          background: #f8f8f8;
          margin-bottom: 10px;

          label {
            color: black;
            font-weight: bold;
            font-size: 13px;
            padding: 10px 10px 0 10px;

            span {
              color: #45A1EF;
              font-size: 12px;
              margin-left: 3px;
            }
          }

          textarea {
            height: 99px;
            resize: none;
            //padding: 0 10px;
          }

          .form-input {
            width: 100%;
            padding: 0 10px;
            border-bottom: 2px solid transparent;

            &:focus {
              border-bottom: 2px solid #45A1EF;
            }

            input, textarea {
              border: 0;
              border-radius: 0;
              background-color: transparent;
              //width: 75%;
            }
          }


          .input-group input:focus, .input-group textarea:focus, .input-group select:focus {
            //box-shadow: none;
          }
        }

        .input-error {
          label {
            span {
              color: red;
            }
          }

          .form-input {
            border-bottom: 2px solid red;
          }
        }

        .input-textarea {
          height: 136px;
        }
      }

      .confirm-message-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .recaptcha-wrapper {
          //width: 46%;
          //overflow: hidden;
        }

        .send-button-content {
          width: 50%;
          display: flex;
          flex-direction: column;
          //align-items: flex-end;
          justify-content: center;
          margin-top: 10px;

          .button-wrapper {
            width: 100%;

            .link-button {
              width: 100%;
              background-color: white;
              box-shadow: 0px 3px 6px #00000029;
              color: #45A1EF;
              height: 50px;
              font-size: 14px;
              display: flex;
              justify-content: center;

              .flex-row {
                display: flex;
                align-items: center;
              }

              .ant-spin {
                margin-left: 10px;
                padding-bottom: 2px;
              }
            }

            .error-text {
              font-size: 12px;
              text-align: center;
              margin-top: 5px;

              i {
                color: #45A1EF;
              }
            }
          }
        }
      }

    }
  }
}

@media all and (max-width: 1300px) {
  .contact-page {
    .contact-top-wrapper {
      .container-contacts {
        width: 400px;
      }

      .bg-containers {
        .light-blue {
          width: 600px;
          height: 400px;
        }

        .dark-blue {
          width: 90%;
          height: 60vh;
        }
      }

      .map-wrapper {
        //width: 60%;
        height: 500px;
        top: 50px;
      }
    }

    .contact-wrapper-bottom {
      height: 290px;

      img {
        top: -250px;
        height: 540px;
      }

      .send-message {
        width: 50%;
        //height: 400px;
        position: absolute;
        top: -195px;
        padding: 35px;

        .send-message-title {
          margin-bottom: 20px;
        }

        .form-wrapper {

          .left, .right {
            width: 46%;
          }

          .input-content {
            width: 100%;
            margin-bottom: 10px;
          }

          .input-textarea {
            //height: calc(100% - 10px);
          }
        }

        .send-button-content {
          .button-wrapper {

            .link-button {
              height: 40px;
              font-size: 12px;
            }

            .error-text {
              font-size: 12px;
              text-align: center;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 1000px) {
  .contact-page {
    .contact-top-wrapper {
      .container-contacts {
        width: 400px;
        top: 500px;
        height: 300px;
      }

      .bg-containers {
        .light-blue {
          width: 380px;
          height: 300px;
        }

        .dark-blue {
          width: 90%;
          height: 700px;
        }
      }

      .map-wrapper {
        //width: 60%;
        height: 400px;
        top: 50px;
      }
    }

    .contact-wrapper-bottom {
      height: 600px;

      img {
        top: -200px;
        height: 540px;
        width: 100%;
      }

      .send-message {
        width: 60%;
        //height: 400px;
        position: absolute;
        top: -50px;
        padding: 35px;
        right: 20%;

        .send-message-title {
          margin-bottom: 20px;
        }

        .form-wrapper {
          flex-direction: column;

          .left, .right {
            width: 100%;
          }

          .input-content {
            width: 100%;
          }

          .input-textarea {
            height: calc(100% - 10px);
          }
        }

        .confirm-message-wrapper {
          .send-button-content {
            width: 100%;

            .button-wrapper {
              width: 100%;

              .link-button {
                height: 40px;
                font-size: 12px;
              }

              .error-text {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 700px) {
  .contact-page {
    padding: 20px 0 0 0;

    .page-header {
      padding-left: 20px;
    }

    .contact-top-wrapper {
      .container-contacts {
        width: 300px;
        top: 500px;
        margin-left: 20px;
        //left: 20%;
      }

      .bg-containers {
        .light-blue {
          width: 150px;
        }

        .dark-blue {
          width: 100%;
        }
      }

      .map-wrapper {
        width: 100%;
      }
    }

    .contact-wrapper-bottom {
      .send-message {
        width: 85%;
        position: absolute;
        padding: 20px;
        right: 10%;

        .send-message-title {
          margin-bottom: 20px;
        }

        .form-wrapper {
          flex-direction: column;

          .left, .right {
            width: 100%;
          }
        }

        .send-button-content {
          .button-wrapper {
            .link-button {
              height: 50px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 450px) {
  .contact-page {
    .logo {
      width: 80px;
      height: 40px;
    }

    .contact-top-wrapper {

      .container-contacts {
        width: 100%;
        padding-right: 30px;
        top: 380px;
        margin-left: 10px;

        .ant-collapse {

          .ant-collapse-item {

            .ant-collapse-content {

              .ant-collapse-content-box {

                .info-wrapper {
                  .info-content {
                    flex-direction: column;
                    margin-bottom: 10px;

                    .title {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .bg-containers {
        .light-blue {
          width: 100px;
          height: 200px;
        }

        .dark-blue {
          width: 100%;
        }
      }

      .map-wrapper {
        height: 300px;
        width: 100%;
        right: 0;
      }
    }

    .contact-wrapper-bottom {
      img {

      }

      .send-message {
        //top: -40%;
        padding: 15px;

        .send-message-title {
          font-size: 22px;
        }
      }
    }
  }
}
