@import "../mainColors";
.distribution-wrapper {
  padding-right: 100px;
  display: flex;
  align-items: center;
  height: 100vh;
  .distribution-left-block {
    background-image: linear-gradient(to bottom, #D9ECFC 40%, $bgGreyColor 0%);
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .distribution-left-content {
      margin-top: 100px;
      height: 60vh;
    }
  }
  .distribution-right-block {
    height: 50vh;
    margin-top: 60px;
    .distribution-right-content {
      .distribution-header {
        margin: 50px 0;
        color: black;
        font-weight: bold;
        font-size: 40px;
      }
      .distribution-text {
        font-size: 18px;
        color: black;
        font-weight: 600;
        height: 104px !important;
        overflow: hidden;
        font-family: Regular;
      }
    }
    .distribution-read-more-btn {
      margin: 60px 0;
    }
  }
  @media screen and (max-width: 991px) {
    padding-right: 0;
    height: auto;
    .distribution-right-block {
      height: auto;
      margin-left: 16px;
    }
  }
  @media screen and (max-width: 600px) {
    .distribution-right-block {
      .distribution-right-content {
        .distribution-header {
          font-size: 30px;
        }
      }
    }
  }
}