.contact-map-marker{
  position: relative;
  .active-map-marker{
    width: 30px;
    height: 50px;
  }
  img{
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 20px;
    height: 35px;
  }
}