.management-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to bottom, #D9ECFC 50%, #fff 50%);
  .management-left-block {
    padding-left: 100px;
    padding-right: 0;
    margin-top: 60px;
    .management-left-content {
      padding: 60px;
      background-image: url("../../images/homepage/homepage_management_left_bg.svg");
      background-color: white;
      background-size: cover;
      .management-header {
        margin: 50px 0;
        color: black;
        font-weight: bold;
        font-size: 40px;
      }
      .management-text {
        font-size: 18px;
        color: black;
        opacity: 0.67;
        height: 107px !important;
        overflow: hidden;
        font-family: Regular;
      }
      .management-links {
        margin-top: 100px;
        display: flex;
        flex-wrap: wrap;
        a {
          color: #000;
          opacity: 0.45;
          text-decoration: none;
          padding-right: 20px;
          padding-left: 0;
          font-size: 18px;
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
    .management-read-more-btn {
      text-align: right;
      margin-top: 14px;
    }
  }
  .management-right-block {
    height: 70vh;
    margin-top: 160px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 1200px) {
    background-image: none;
    .management-left-block {
      padding-left: 0;
    }
    .management-right-block {
      margin-top: 30px;
    }
  }
  @media screen and (max-width: 991px){
    height: auto;
  }
  @media screen and (max-width: 768px){
    .management-left-block {
      margin-top:0;
    }
  }
  @media screen and (max-width: 600px) {
    .management-left-block {
      .management-left-content {
        padding: 15px;
        .management-header {
          margin: 30px 0;
          font-size: 30px;
        }
        .management-text {
          font-size: 16px;
          height: 97px !important;
        }
        .management-links {
          margin-top: 60px;
          a {
            font-size: 16px;
          }
        }
      }
    }
  }
}