$dark: #272727;
.review-slider {
  overflow: hidden;
  position: relative;
  height: 100vh;

  .slick-slider {
    .slick-track {
      height: 100%;

      .slick-slide {
        > div {
          height: 100%;
        }
      }
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .slider-caption {
    width: 100%;
    background-color: rgba(69, 161, 239, .45);
    position: absolute;
    top: 0px;
    left: 0px;
    color: white;
    font-size: 16px;
    overflow: hidden;
    line-height: 25px;
    letter-spacing: 0.5px;

    .slider-wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      justify-content: center;
      height: 100vh;
      width: 70%;

      .review-quota {
        object-fit: contain;
        width: 60px;
        height: 60px;
      }

      .slider-box {
        width: 70%;
        border-radius: 6px;

        .slider-item {
          .review-text {
            font-size: 36px;
            color: #fff;
            font-weight: 700;
            line-height: 48px;
            margin: 30px 0;
            max-height: 389px;
            overflow: hidden;
          }
        }
      }
    }
  }

  .slick-list {
    border-radius: 6px 0 0 6px;
    height: 100%;
  }

  @media screen and (max-width: 1200px) {
    .slider-caption {
      .slider-wrapper {
        .slider-box {
          width: 80%;
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    .slider-caption {
      .slider-wrapper {
        .slider-box {
          width: 90%;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .slider-caption {
      .slider-wrapper {
        width: 90%;
        .slider-box {
          width: 100%;
          .slider-item {
            .review-text {
              font-size: 24px;
              line-height: 32px;
            }
          }
        }
      }
    }
  }
}
