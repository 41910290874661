@import "../mainColors";

$dangerMode: #FF3F62;
$warningMode: #ff7337;
$successMode: #50e181;
$joinMode: #846cff;

.alert-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 450px;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 1500;
}

.alert-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  bottom: 0;
  background: rgba(0, 0, 0, .35);
}

.alert-dialog {
  padding: 20px;

  .dialog-header {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .dialog-title {
      h3 {
        font-size: 32px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
        color: black;
      }

      h5 {
        font-size: 14px;
        margin-top: 4px;
        font-weight: 400;
        color: $dangerMode;
        margin-bottom: 0;
      }
    }

    .dialog-closing-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
      height: 20px;
      background: none;
      border: none;
      cursor: pointer;

      i {
        color: #a5afb8;
        font-size: 20px;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .dialog-content {
    margin-bottom: 30px;

    p {
      color: #999;
      font-size: 18px;
      font-weight: 300;
      margin: 0;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;

    button {
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      box-shadow: none;
      border: none;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      padding: 10px 0;
      width: 92px;
      margin: 0;
      cursor: pointer;
      background: white;
      color: white;

      &.Cancel, &.Close {
        border: 1px solid #818fa2;
        color: #818fa2;
      }

      &.Delete, &.Unsave, &.Remove, &.Out {
        border: 1px solid $dangerMode;
        background: $dangerMode;
      }

      &.Block, &.Yes {
        border: 1px solid $warningMode;
        background: $warningMode;
      }

      &.Ok {
        border: 1px solid $brandColor;
        background: $brandColor;
      }

      &.Join, &.Message {
        border: 1px solid $joinMode;
        background: $joinMode;
      }

      &:last-child {
        margin-left: 15px;
      }
    }
  }
}

.swal-modal {
  .swal-content {
    .alert-dialog {
      padding: 0;

      .dialog-content {
        margin-bottom: 0;
        text-align: left;
      }
    }
  }

  .swal-button--confirm {
    box-shadow: none !important;
    border: 1px solid #818fa2;
    background: white;
    color: #818fa2;

    &:hover {
      background: white;
    }
  }
}

@media (max-width: 500px) {
  .alert-modal {
    width: 300px;
  }
  .alert-dialog {
    padding: 15px;

    .dialog-header {
      margin-bottom: 20px;

      .dialog-title h3 {
        font-size: 18px;
      }

      .dialog-closing-btn {
        top: -5px;
        right: -5px;
        height: 16px;

        i {
          font-size: 16px;
        }
      }
    }

    .dialog-content {
      margin-bottom: 20px;

      p {
        font-size: 14px;
      }
    }

    .action-buttons {
      button {
        padding: 6px 0;
        width: 85px;
        font-size: 13px;

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}