 .partner-item-wrapper{
   width: 100%;
   background: #FFF;
   box-shadow: 0 2px 4px #00000029;
   border-radius: 4px;
   padding-bottom: 75%;
   position: relative;
   transition: all .3s;
   &:hover {
     transform: scale(1.06);
   }
   img {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
   }
 }