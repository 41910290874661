@import "../mainColors";

.vacancies-page-wrapper {
  padding: 40px 60px;
  background-color: $bgGreyColor;
  height: 100%;

  .vacancies-page-title {
    margin: 20px 0;
    font-weight: bold;
  }

  .vacancies-header {
    width: 100%;
    height: 150px;
    background-color: $bgBlueColor;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vacancies-content {
    width: 60%;
    margin: 20px auto;
    img{
      transition: all .3s;
    }
    .activeAddress{
      transform: rotate(45deg)!important;
      top: 40% !important;
    }

    .ant-collapse {
      width: 100%;
      border: 0;
      background-color: transparent;

      .ant-collapse-item {
        border: 0;
        margin-bottom: 10px;
        box-shadow: 0 2px 4px #00000029;

        .ant-collapse-header {
          background-color: white;
          border-radius: 4px;
          font-weight: bold;
          height: 60px;
          display: flex;
          align-items: center;

          .ant-collapse-arrow {
            width: 12px;
            height: 12px;
          }
        }
        .ant-collapse-content {
          border: 0;

          .ant-collapse-content-box {
            background: #f8f8f8;

            .description{
              width: 80%;
              @media all and (max-width: 600px){
                  width: 100%!important;
              }
              p{
                margin-bottom: 18px;
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }

            .info-wrapper {
              display: flex;

              .title {
                width: 120px;
                font-weight: bold;

                div {
                  margin-bottom: 20px;
                }
              }

              .info {
                div {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .vacancies-section{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding-bottom: 40px;
    .section-img{
      width: 50%;
      height: 480px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .section-content{
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .section-title{
        font-weight: bold;
        padding: 0 60px;
      }
      .section-description{
        padding: 0 60px;
      }
    }
  }
}

@media all and (max-width: 1100px) {
  .vacancies-page-wrapper {
    padding: 40px 40px;
    .vacancies-content {
      width: 60%;
    }

    .vacancies-section{
      .section-img{
        width: 50%;
        height: 380px;
      }
      .section-content{
        width: 50%;
        .section-title{
          padding: 0 40px;
          font-size: 22px;
        }
        .section-description{
          padding: 0 40px;
          font-size: 13px;
        }
      }
    }
  }
}

@media all and (max-width: 800px) {
  .vacancies-page-wrapper {
    padding: 20px;
    .vacancies-header{
      font-size: 13px;
      text-align: center;
      height: 120px;
      padding: 0 10px;
    }
    .vacancies-content {
      width: 100%;
    }
    .vacancies-section{
      flex-wrap: wrap;
      .section-img{
        width: 100%;
        height: 250px;
        order: 1;
      }
      .section-content{
        width: 100%;
        order: 2;
        .section-title{
          padding: 40px 0 10px 0;
          font-size: 22px;
        }
        .section-description{
          padding: 40px 0 10px 0;
          font-size: 12px;
        }
      }
    }
  }
}
