@import "../mainColors";

.search-page {
  padding: 60px;
  min-height: 100vh;

  .search-card{
    width: 100%;
    height: 60px;
    background-color: white;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .no-search-result{
    text-align: center;
    font-size: 40px;
    margin-top: 15%;
    //font-family: Bold;
  }
}


@media all and (max-width: 576px) {
  .search-page {
    padding: 20px;
    .search-header{
      display: flex;
      flex-direction: column;
      .ant-dropdown-link{
        position: initial;
        //font-size: 22px;
      }
    }
    .search-content {
      justify-content: center;
    }
    .no-search-result{
      font-size: 30px;
      margin-top: 25%;
    }
  }
}
