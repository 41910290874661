.contact-block {
  position: relative;

  .contact-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    padding: 68px;

    .contact-left-part {
      .contact-header {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 25px;
      }

      .contact-left-box {
        box-shadow: 0px 3px 6px #00000029;
        width: 550px;
        padding: 70px;
        background-color: #fff;
        height: fit-content;

        .contact-input {
          background-color: rgba(0, 0, 0, .03);
          margin-bottom: 20px;
          font-size: 14px;

          label {
            margin-left: 12px;
            margin-top: 12px;
            font-weight: bold;

            span {
              color: #45A1EF;
            }
          }

          .form-input {
            border-bottom: 2px solid transparent;
            background-color: transparent;
            width: 100%;
            padding-left: 12px;
            resize: none;

            &:focus {
              border-bottom: 2px solid #45A1EF;
            }
          }
        }

        .input-error {
          label {
            span {
              color: red;
            }
          }

          .form-input {
            border-bottom: 2px solid red;
          }
        }

        .recaptcha-wrapper {
          display: flex;
          justify-content: center;
          height: 70px ;
          margin-bottom: 10px;
        }

        .link-button {
          background-color: #fff;
          color: #45A1EF;
          box-shadow: 0px 3px 6px #00000029;
          width: 100%;

          &:hover {
            background-color: #fcfcfc;
            box-shadow: 0px 4px 10px #00000029;
          }

          .ant-spin {
            margin-left: 10px;
            padding-bottom: 2px;
          }
        }

        .contact-left-text {
          text-align: center;
          font-weight: 600;
          font-size: 15px;
          color: rgba(0, 0, 0, .75);
          margin-top: 20px;

          span {
            color: #45A1EF;
          }
        }
      }
    }

    .contact-right-part {
      margin-top: 20px;
      width: 100%;
      position: relative;

      .map-bg {
        position: absolute;
        height: 80px;
        top: -15px;
        left: -25px;
        width: 80%;
        z-index: -1;
        background-color: rgba(69, 161, 239, .5);
      }

      .map-block {
        width: 100%;
        //margin-top: -50px;
        .map {
          width: 100%;
          height: 572px;
          background-color: #D5D5D5;
          position: relative;

          a {
            position: absolute;
            bottom: 40px;
            right: 60px;
          }
        }

        .contact-information {
          display: flex;
          flex-wrap: wrap;
          padding-left: 20px;
          padding-top: 22px;

          > div {
            flex-grow: 1;
          }

          .info-header {
            font-size: 18px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 12px;
            font-family: Bold;
          }

          .contact-info-text {
            font-weight: 400;
            color: #fff;
            opacity: 0.75;
          }
        }
      }
    }
  }
  .contact-bottom {
    width: 100%;
    height: 300px;
    background-color: #45A1EF;
    position: absolute;
    bottom: 0px;
    z-index: 1
  }
  @media all and (max-width: 1600px) {
    .contact-wrapper {
      .contact-left-part {
        //margin-top: -90px;
        .contact-left-box {
          width: 500px;
          padding: 40px;

          .contact-input {
            input {
              padding-bottom: 12px;
            }
          }
        }
      }

      .contact-right-part {
        .map-bg {
          margin-bottom: 13px;
        }
      }
    }
  }
  @media all and (max-width: 1300px) {
    .contact-wrapper {
      .contact-left-part {
        .contact-header {
          font-size: 32px;
        }

        .contact-left-box {
          width: 400px;
          //padding: 60px 40px
        }
      }
    }
  }
  @media all and (max-width: 1200px) {
    .contact-wrapper {
      padding: 50px 20px;

      .contact-left-part {
        .contact-left-box {
          //width: 350px;
          padding: 30px
        }
      }

      .contact-right-part {
        .map-block {
          .map {
            height: 545px;
          }

          .contact-information {
            .info-header, .contact-info-text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1000px) {
    .contact-wrapper {
      flex-direction: column;
      padding: 0px;

      .contact-left-part {
        margin: 70px 0;

        .contact-header {
          text-align: center;
        }

        .contact-left-box {
          width: 550px;
          position: relative;
          padding: 70px;
          margin: 0 auto;
        }
      }

      .contact-right-part {
        margin-top: -40px;

        .map-bg {
          margin-left: 0;
          width: 100%;
          height: 120px;
        }
      }
    }
  }
  @media screen and (max-width: 620px) {
    .contact-wrapper {
      .contact-left-part {
        .contact-left-box {
          width: 96%;
          padding: 20px;
        }
      }
    }
  }
  @media all and (max-height: 900px) {
    .contact-wrapper {
      padding-bottom: 40px;

      .contact-left-part {
        .contact-header {
          margin-bottom: 18px;
          font-size: 35px;
        }

        .contact-left-box {
          padding: 50px;

          .contact-input {
            height: 70px;

            &:nth-child(3) {
              height: 100px;
            }
          }
        }
      }
      .contact-right-part {
        .map-block {
          .map {
            height: 540px;
          }
        }
      }
    }
    .contact-bottom {
      height:250px;
    }
  }
  @media all and (max-height: 800px) {
    .contact-wrapper {
      .contact-left-part {

        .contact-left-box {
          padding: 40px;
        }
      }
      .contact-right-part {
        .map-block {
          .map {
            height: 500px;
          }
        }
      }
    }
  }
  @media all and (max-height: 750px) {
    padding-top: 40px;
    .contact-wrapper {
      padding-bottom: 20px;

      .contact-left-part {
        .contact-header {
          margin-bottom: 15px;
          font-size: 28px;
        }

        .contact-left-box {
          padding: 30px;

          .contact-input {
            height: 70px;

            &:nth-child(3) {
              height: 100px;
            }
          }

          .link-button {
            padding: 10px 32px;
          }
        }
      }

      .contact-right-part {
        .map-block {
          .map {
            height: 470px;
          }
        }
      }
    }
    .contact-bottom {
      height: 200px;
    }
  }
  @media all and (max-height: 650px) {
    .contact-wrapper {
      .contact-left-part {
        .contact-header {
          margin-bottom: 10px;
          font-size: 26px;
        }

        .contact-left-box {
          padding: 20px 30px;

          .contact-input {
            height: 65px;
            margin-bottom: 15px;

            &:nth-child(3) {
              height: 90px;
            }

            label {
              margin: 8px 0 8px 12px;
            }

            input, textarea {
              padding-bottom: 6px;
            }
          }

          .link-button {
            padding: 10px 32px;
          }

          .contact-left-text {
            font-size: 14px;
            margin-top: 15px;
          }
        }
      }

      .contact-right-part {
        .map-block {
          .map {
            height: 400px;
          }
        }
      }
    }
    .contact-bottom {
      height: 200px;
    }
  }


}
