@import "../mainColors";
.partners-page-wrapper{
  padding: 40px 60px;
  background-color: $bgGreyColor;
  min-height: 100vh;
  .partners-page-title {
    margin: 20px 0;
    font-weight: bold;
  }
  .partners-wrapper{
    display: flex;
    flex-wrap: wrap;
    .partner-item{
      width: 20%;
      padding: 10px;
    }
  }
}

@media all and (max-width: 1000px) {
  .partners-page-wrapper{
    padding: 20px 30px;
    .partners-wrapper{
      .partner-item{
        width: 25%;
        padding: 10px;
      }
    }
  }
}
@media all and (max-width: 600px) {
  .partners-page-wrapper{
    padding: 20px;
    .partners-page-title{
      font-size: 28px;
    }
    .partners-wrapper{
      .partner-item{
        width: 50%;
        padding: 5px;
      }
    }
  }
}
