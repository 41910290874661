$dark:  #272727;
.home-page-slider {
  overflow: hidden;
  position: relative;
  background: #fff;
  &.height-100 {
    height: 100vh;
  }
  .slick-slider {
    height: 100%;
    .slick-track {
      height: 100%;
      .slick-slide {
        >div {
          height: 100%;
        }
      }
    }
  }
  .slider-item {
    display: flex !important;
    border-radius: 6px;
    position: relative;
    height: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .slider-caption{
      position: absolute;
      bottom: 0px;
      left: 0px;
      .slider-caption-logo {
        padding: 12px;
        background: rgba(0,0,0,.2);
        img {
          width: 100px;
          margin-bottom: 12px;
          filter: brightness(0) invert(1);
          object-fit: contain;
        }
      }
      .slider-description {
        background: white;
        padding: 25px 12px 70px 12px;
        .description-header {
          color: #000;
          font-size: 24px;
          font-weight: bold;
          line-height: 32px;
          letter-spacing: 0;
          margin-bottom: 0;
          a {
            color: #45A1EF;
            text-decoration: underline;
          }
        }
        .description-text {
          font-size: 16px;
          font-weight: 500;
          color: #000;
          padding-top: 20px;
          height: 140px;
          overflow: hidden;
          margin-bottom: 20px;
        }
        .description-btn {
          text-transform: uppercase;
          color: #45A1EF;
          font-weight: bold;
          img {
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-top: -5px;
            margin-left: 5px;
          }
        }
      }
    }
  }

  .slider-arrows {
    display: flex;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    .slider-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      width: 64px;
      height: 64px;
      color: #000;
      transition: all .3s;
      cursor: pointer;
      &:hover {
        background-color: #45A1EF;
        color: white;
        img {
          filter: brightness(0) invert(1);
        }
      }
      img {
        filter: brightness(.2) invert(.2);
        transition: all .3s;
        width: 18px;
        height: 32px;
      }
    }
  }

  .slick-list {
    border-radius: 6px 0 0 6px;
    height: 100%;
  }
  @media screen and (min-width: 1600px) {
    .slider-item {
      .slider-caption{
        .slider-caption-logo {
          padding: 12px 96px;
        }
        .slider-description {
          padding: 12px 96px;
          padding-bottom: 72px;
          .description-header {
            font-size: 32px;
            line-height: 42px;
          }
          .description-text {
            font-size: 18px;
            padding: 30px 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .slider-arrows {
      right: 5px;
    }
  }
  @media screen and (max-width: 767px) {
    height: unset;
    .slider-item {
      flex-direction: column;
      >img {
        height: 320px;
        z-index: 111;
      }
      .slider-caption{
        position: relative;
        .slider-caption-logo {
          position: absolute;
          top: -92px;
          width: 100%;
          z-index: 1000000;
          height: 92px;
        }
      }
    }
    .slider-arrows {
      right: 15px;
    }
  }
  @media screen and (max-width: 500px) {
    .slider-item {
      .slider-caption{
        .slider-description {
          .description-header {
            font-size: 20px;
            line-height: 24px;
          }
          .description-text {
            height: 165px;
          }
          .description-btn {
            font-size: 14px;
            img {
              width: 8px;
              height: 8px;
              object-fit: contain;
            }
          }
        }
      }
    }
    .slider-arrows {
      .slider-btn {
        height: 50px;
        width: 50px;
      }
    }
  }
}