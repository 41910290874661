@import "../mainColors";

.layout-navigation-wrapper {
  text-align: right;

  .white-header{
    background-color: white;
    visibility: visible;
    opacity: 1;
    img{
      filter: unset;
    }
  }

  .scrolling-lines {
    margin-top: 100px;
    width: 15px;
    display: none;

    ul {
      position: relative;
      cursor: pointer;

      &.selected-section {
        li.default-section-line {
          transition: .2s;
          opacity: 0;
        }

        span {
          transition: .2s;
          opacity: 1;
        }

        li.active-section-line {
          transition: .2s;
          opacity: 1;
        }
      }

      span {
        position: absolute;
        top: -8px;
        left: 0px;
        color: #bababa;
        font-size: 13px;
        opacity: 0;
      }

      .active-section-num {
        color: black;
      }

      li {
        border-top: 1px solid #bababa;
        margin-bottom: 25px;


        &.active-section-line {
          transition: .3s;
          position: absolute;
          border-top: 2px solid black;
          width: 30px;
          left: 22px;
          opacity: 0;
        }

      }
    }
  }

  .header {
    width: 1600px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 145px;

    img {
      width: 100px;
      object-fit: cover;
      align-self: self-start;
      margin-top: 15px;
    }
  }

  .navMenu {
    width: 0;
    right: 0;

    .toggle-btn {
      position: fixed;
      top: 0;
      right: 0;
      width: 80px;
      height: 80px;
      color: white;
      background: #45A1EF;
      z-index: 101;

      i {
        font-size: 40px;
      }
    }

    .scrollMenu {
      position: absolute;
      cursor: pointer;
      bottom: 60px;
      img{
        padding: 0 30px;
      }
    }
  }

  .opened-menu-content {
    width: 360px;
    height: 100vh;
    position: fixed;
    right: 0;
    transform: translateX(100%);
    z-index: 101;
    text-align: left;
    background: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    transition: all ease-out 0.2s;

    .scrollMenu {
      position: absolute;
      bottom: 60px;
    }

    &.menu-opened {
      transform: translateX(0);
    }

    .close-btn {
      background: transparent;
      color: #45A1EF;
      margin: 10px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .menu-item {
      text-transform: uppercase;
      transform: translateX(0px);
      opacity: 1;
      color: #000;
      font-size: 18px;
      font-weight: bold;
      margin: 6px 0;

      .ant-menu-submenu-title {
        font-size: 18px;
        color: rgba(0, 0, 0, .65);

        + ul {
          li {
            font-size: 15px;
            margin: 0;
            font-weight: 600;
          }
        }
      }

      a:hover {
        text-decoration: none;
        color: #000;
        opacity: 1;
      }

      &:active {
        background: transparent;
      }
    }

    ul {
      li:last-child {
        margin-bottom: 16px;
      }
    }


    .menu-search-container {
      padding: 24px;
      float: left;

      .menu-search-input {
        border-bottom: 2px solid #45A1EF;
        display: flex;
        float: left;
        flex-grow: 1;
        align-items: center;
        height: 100%;
        width: 75%;
        padding-bottom: 4px;
        margin-right: 24px;

        input {
          height: 100%;
          background-color: transparent;
          border: none;
          flex-grow: 1;
          outline: none;
          width: 100%;
        }

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .social-icons {
      position: absolute;
      bottom: 20px;

      li {
        display: inline-block;
        margin-left: 24px !important;
      }
    }

    .language-selector {
      position: absolute;
      top: 27px;
      right: 60px;

      .menu-language {
        white-space: nowrap;

        > img {
          margin-left: 8px;
          margin-top: -2px;
        }

        .language-icon {
          width: 22px;
          height: 18px;
          object-fit: cover;
          margin-right: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .scrolling-lines {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
      .navMenu {
        min-width: 55px;

        .toggle-btn {
          width: 55px;
          height: 55px;
        }
      }
    }
  @media screen and (max-width: 500px) {
    .opened-menu-content {
      width: 100%;

      .menu-search-container {
        width: 100%;
        margin-top: 40px;
        padding-right: 20px;

        .menu-search-input {
          width: 100%;
        }
      }

      .language-selector {
        top: 17px;
        right: unset;
        left: 17px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .opened-menu-content {
      .menu-search-container {
        float: none;
        margin-top: 40px;

        .menu-search-input {
          input {
            //width: calc(100% - 80px);
          }
        }
      }
    }
  }

  @media screen and (max-height: 800px) {
    .navMenu {
      .scrolling-lines {
        margin-top: 0;
      }

      .scrollMenu {
        bottom: 40px;
      }
    }
  }
  @media screen and (max-height: 650px) {
    .navMenu {
      .scrolling-lines {
        margin-bottom: 30px;
      }

      .scrollMenu {
        bottom: 20px;

        img {
          height: 120px;
        }
      }
    }
  }
}
@keyframes show-menu {
  from {visibility: hidden; height: 0;  opacity: 0;}
  to {visibility: visible;height: 80px;  opacity: 1;}
}
@keyframes hide-menu {
  from {height: 80px; opacity: 1;}
  to {height: 0; opacity: 0;}
}
@keyframes show-menu-small {
  from {visibility: hidden; height: 0;  opacity: 0;}
  to {visibility: visible;height: 55px;  opacity: 1;}
}
@keyframes hide-menu-small {
  from {height: 55px; visibility: visible; opacity: 1;}
  to {height: 0; visibility: hidden; opacity: 0;}
}

.header-wrapper {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(0,0,0,.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: .3s;
  animation-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  img {
    cursor: pointer;
    width: 100px;
    height: 100%;
    filter: brightness(0) invert(1);
    object-fit: contain;
    margin-left: 80px;
  }

  @media screen and (max-width: 1200px) {
      img {
        margin-left: 20px;
      }
  }
  @media screen and (max-width: 800px) {
    height: 55px;
    img {
      width: 70px;
    }
  }
}

.show{
  visibility: visible;
  opacity: 1;
  animation-name: show-menu;
  @media screen and (max-width: 800px) {
    animation-name: show-menu-small;
  }
}
.hide{
  visibility: hidden;
  animation-name: hide-menu;
  @media screen and (max-width: 800px) {
    animation-name: hide-menu-small;
  }
}
