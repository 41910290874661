@import "../_mainColors";

.about-page {
  padding-top: 60px;
  overflow: hidden;

  .page-header {
    padding-left: 80px;
  }

  .about-company {
    padding-left: 80px;

    .about-company-left-part {
      .about-page-description {
        line-height: 22px;
        display: inline-block;

        h1, h2 {
          line-height: 30px;
          font-size: 24px !important;
          background-color: transparent !important;
        }

        p {
          font-size: 14px !important;
          background-color: transparent !important;

          span {
            background-color: transparent !important;
            font-size: 14px !important;
          }

        }
      }

      table {
        width: 100%;

        tbody {
          width: 100%;

          tr {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            td {
              border-top: none;
              width: 24.5%;
              @media screen and (max-width: 600px) {
                width: 49.5%;
              }

              figure {
                display: inline;

                img {
                  width: 100%;
                }
              }
            }
          }
        }

        img {
          //width: 25%;
        }
      }

      p {
        margin-top: 20px;
        font-weight: 500;
      }

      div {
        margin-top: 20px;
        color: #000;
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
        letter-spacing: 0;

        a {
          color: $bgBlueColor;
          text-decoration: underline;
        }
      }

      .brands-block {
        display: flex;
        flex-wrap: wrap;

        div {
          flex-grow: 1;
        }
      }
    }

    .about-company-right-part {
      .about-company-right-bg {
        position: absolute;
        z-index: -1;
        width: 80%;
        height: 200px;
        right: 0;
        background-color: rgba(69, 161, 239, .54);
      }

      img {
        margin-top: 80px;
      }
    }

    .about-company-bottom-bg {
      margin: -80px auto -30px;
      width: 300px;
      height: 200px;
      background-color: rgb(217, 236, 252);
    }
  }

  .about-second-section {
    display: flex;
    align-items: center;

    .about-second-section-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: 500;
      padding-left: 20px;
      margin-top: 40px;
    }
  }

  .about-distribution-section {
    padding-bottom: 80px;
    background-color: $bgBlueColor;

    .about-distribution-section-left {
      padding: 0 40px;

      p {
        color: #fff;
        margin-bottom: 40px;
        font-weight: 500;

        span {
          color: white !important;
        }
      }

      .about-distribution-header {
        margin: 60px 0;
        font-weight: bold;
        font-size: 40px;
      }
    }

    .about-distribution-section-right {
      .about-distribution-right-bg {
        display: block;
        width: 50%;
        height: 100px;
        background-color: #D9ECFC;
        margin-left: auto;
        margin-top: 60px;
      }
    }
  }

  .about-management-section {
    margin-top: -80px;

    .about-management-section-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 80px;

      p {
        font-weight: 500;
        margin-bottom: 40px;
      }

      .about-management-header {
        margin-top: 80px;
        font-weight: bold;
        font-size: 40px;
      }
    }

    .about-strategies {
      padding: 120px 80px;

      .about-strategies-left {
        .about-strategies-title {
          font-size: 40px;
          font-weight: bold;
          margin-bottom: 30px;

          span:last-child {
            color: $bgBlueColor;
          }

          span:first-child {
            color: black;
          }
        }

        ol {
          list-style-type: none;
          display: flex;
          flex-wrap: wrap;
          padding: 0;

          li {
            width: 50%;
            display: flex;
            margin-bottom: 30px;
            font-size: 14px !important;
            background-color: transparent !important;

            span {
              background-color: transparent !important;

              &:first-child {
                color: $bgBlueColor !important;
                font-size: 42px !important;
                font-weight: bold;
                display: block;
                margin: 0 10px;
              }

              &:not(:first-child) {
                font-size: 14px !important;
              }
            }

          }

          li:nth-child(odd) {
            padding-right: 25px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .about-company {
      .about-company-left-part {
        padding-right: 100px;

        p {
          margin-top: 30px;
        }

        div {
          margin-top: 30px;
          color: #000;
          font-size: 32px;
          font-weight: bold;
          line-height: 42px;
          letter-spacing: 0;
        }
      }
    }
    .about-second-section {
      .about-second-section-right {
        padding-left: 60px;
        margin-top: 0;
      }
    }
    .about-distribution-section {
      padding-bottom: 150px;
      margin-top: -60px;

      .about-distribution-section-left {
        padding: 0 80px;

        .about-distribution-header {
          margin: 200px 0 80px;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .page-header {
      padding-left: 0;
    }
    .about-company-right-part {
      .about-company-right-bg {
        display: none;
      }
    }
    .about-second-section {
      margin: 80px 0;
    }
    .about-company-bottom-bg {
      display: none;
    }
    .about-page-header, .about-company {
      padding-left: 20px;
    }
    .about-distribution-section {
      .about-distribution-section-right {
        .about-distribution-right-bg {
          display: none;
        }
      }
    }
    .about-management-section {
      margin-top: 10px;

      .about-management-section-left {
        align-self: center;
      }

      .about-management-section-right {
        padding: 0 0 0 20px;

        .about-management-header {
          margin-top: 10px;
        }
      }

      .about-strategies {
        padding: 40px 20px;

        .about-strategies-left {
          p {
            font-size: 30px;
            line-height: 36px;
            @media screen and (max-width: 991px){
              font-size: 20px;
              line-height: 28px;
            }
            @media screen and (max-width: 600px){
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .about-second-section {
      margin: 0 0 40px;

      .about-second-section-right {
        order: -5;
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding-top: 20px;
    .about-company {
      .about-company-left-part {
        div {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
    .about-distribution-section {
      .about-distribution-section-left {
        .about-distribution-header {
          font-size: 30px;
        }
      }
    }
    .about-management-section {
      .about-management-section-right {
        .about-management-header {
          margin-top: 30px;
          font-size: 30px;
        }
      }

      .about-strategies {
        .about-strategies-left {
          ol {
            li {
              width: 100%;
            }

            li:nth-child(odd) {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}
